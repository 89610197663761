<script lang="jsx">
import ProductItemAddToBag from './widgets/ProductItemAddToBag.vue'
import ProductItemNormalPrice from './ProductItemNormalPrice.vue'

export default {
  name: 'ProductItemPricesMy',
  functional: true,
  props: {
    // 是否售罄
    isSoldOut: {
      type: Boolean,
      default: true
    },
    salePrice: {
      type: Object,
      default: () => ( {} )
    },
    retailPrice: {
      type: Object,
      default: () => ( {} )
    },
    // 会员专属折扣价
    exclusivePromotionPrice: {
      type: Object,
      default: () => ( {} )
    },
    config: {
      type: Object,
      default: () => ( {} )
    },
    constantData: {
      type: Object,
      default: () => ( {} )
    },
    hasFeedback: {
      type: Boolean,
      default: false
    },
    showOperatinPanel: {
      type: Boolean,
      default: false
    },
    curData: {
      type: Object,
      default: () => ( {} )
    },
    pretreatInfo: {
      type: Object,
      default: () => ( {} )
    },
    multiColumn: {
      type: Boolean,
      default: false
    },
    isPromoPrice: {
      type: Boolean,
      default: false
    },
    showMoreBtn: {
      type: Boolean,
      default: false
    },
    showNormalPrice: {
      type: Boolean,
      default: false
    },
    newFlashPromotion: {
      type: Object,
      default: () => ( {} )
    },
    sheinClubDiscountValue: {
      type: [String, Number],
      default: '',
    },
    showSmallSheinClubLabel: {
      type: Boolean,
      default: false  
    },
    bottomInfoRightGap: {
      type: String,
      default: ''
    },
    // 显示驼峰价格样式
    showCamelPrice: {
      type: Boolean,
      default: false,
    },
    flashTypeText: {
      type: String,
      default: '',
    },
    newFlashLabelUpPriceText: {
      type: String,
      default: '',
    },
    language: {
      type: Object,
      default: () => ({})
    },
    // 折扣信息
    discountInfo: {
      type: Object,
      default: () => ({})
    }
  },
  render(h, { props, data, listeners }) {
    const { config, constantData, curData, pretreatInfo, newFlashPromotion, showNormalPrice, isSoldOut, salePrice, retailPrice, showMoreBtn, multiColumn, isPromoPrice, sheinClubDiscountValue, showSmallSheinClubLabel, bottomInfoRightGap, showCamelPrice, newFlashLabelUpPriceText, discountInfo } = props || {}
    const { IS_RW } = constantData || {}

    const { showAddBagAfterGoodsPrice } = config || {}
    // 降价金额
    const discountAmount = curData?.discountAmount || {}
    // 快加车icon去掉背景
    const noIconBackground = showAddBagAfterGoodsPrice

    // 付费会员
    const sheinClubPrice = curData.sheinClubPromotionInfo?.price || {}

    const showPriceHolder = config.showSheinClubPrice || showNormalPrice
    const { size, backgroundColor } = config?.style?.addBagIcon || {}
    const addBagIconStyle = {
      size,
      backgroundColor,
    }
    const showAddBagIcon = ((config.showAddBagBtn && config.showLikeBtn) || config.showAddBagAfterGoodsPrice) && showNormalPrice && salePrice.amountWithSymbol

    // 判断数据是否是未来期，如果是未来期则显示未来期的样式
    const isFuturePeriod = Number(curData?.promotionInfo?.[0]?.startTime) >= Date.now() / 1000

    let iconEl = null
    if (showAddBagIcon) {
      // 加车 icon
      iconEl = (
        <ProductItemAddToBag
          no-icon-background={noIconBackground}
          box-shadow={false}
          size={addBagIconStyle.size === 'middle' ? 'middle' : 'big'}
          backgroundColor={addBagIconStyle.backgroundColor}
          is-rw={IS_RW}
          vOn:openQuickAdd={listeners['openQuickAdd']}
        />
      )
    } else if (!config.showAddBagAfterGoodsPrice && showMoreBtn && showNormalPrice) {
      // 更多icon
      iconEl = (
        <i
          class="suiiconfont sui_icon_more_20px j-feedback-btn"
          vOn:click_stop={listeners['clickMoreBtn']}
        ></i>
      )
    } else if (isFuturePeriod) {
      iconEl = (
        <img
          src='/pwa_dist/images/flash-sale/flash-sale-remind-882f55b455.svg'
        />)
    }
    return (
      <div
        class={[
          data.class,
          data.staticClass,
          'product-item__prices',
          'product-item__prices_smaller-margin',
          {
            'product-item__prices_holder': showPriceHolder && !multiColumn,
            'product-item__prices_small-holder': showPriceHolder && multiColumn,
          }
        ]}
      >
        {
          <div class={[
            'product-item__normal-price',
            {
              'product-item_soldout': isSoldOut
            }
          ]}>
            {/* 普通价格（售卖价和零售价） */}
            <div class={[
              'product-item__price-container',
              {
                'product-item__price-container-future': isFuturePeriod,
              }
            ]}>
              {
                <ProductItemNormalPrice
                  new-flash-promotion={newFlashPromotion}
                  config={config}
                  sale-price={salePrice}
                  retail-price={retailPrice}
                  discount-amount={discountAmount}
                  pretreat-info={pretreatInfo}
                  multi-column={multiColumn}
                  constant-data={constantData}
                  show-camel-price={showCamelPrice}
                  is-promo-price={isPromoPrice}
                  shein-club-price={sheinClubPrice}
                  shein-club-discount-value={sheinClubDiscountValue}
                  show-small-shein-club-label={showSmallSheinClubLabel}
                  bottom-info-right-gap={bottomInfoRightGap}
                  new-flash-label-up-price-text={newFlashLabelUpPriceText}
                  discount-info={discountInfo}
                  has-right-icon={!!iconEl}
                />
              }
            </div>
            <div class={[
              'product-item__addbag-icon',
              {
                'product-item__addbag_soldout': isSoldOut,
                'product-item__addbag_future': isFuturePeriod
              }
            ]}>
              { iconEl }
            </div>
          </div>
        }
        {/* 折扣 */}
        {
          discountInfo.unitDiscountString ? 
            (<div class={['product-item__hook_count', 
              {
                'product-item__hook_count_future': isFuturePeriod,
              }
            ]}
            >
              {discountInfo.unitDiscountString}
            </div>) : null
        }
      </div>
    )
  }
}
</script>

<style lang="less">
.product-item {
  &__prices {
    position: relative;
    text-align: left;
    font-size: 0; // 解决display: inline-block缝隙
    &_holder {
      min-height: 0.4533rem;
    }
    &_small-holder {
      min-height: 0.3733rem;
    }
    &_soldout {
      opacity: .3;
    }
    &_smaller-margin {
      margin-top: 0.1067rem;
    }
  }
  &__normal-price {
    .flexbox();
    .align-center();
    .space-between();
    position: relative;
    .sui_icon_more_20px { /* stylelint-disable-line */
      position: absolute;
      top: 0;
      right: 0;
      height: 0.4533rem;
      line-height: 0.4533rem;
      color: #959595;
    }
  }
  &__newflash-label {
    margin-top: 0.0533rem;
  }

  &__price-container {
    background: rgba(254, 57, 48, 0.08);
    flex: 1;
    border-radius: 0.11333rem 0 0 0.11333rem;
    padding: 0.11rem 0.12rem;

    .normal-price__camel-price {
      line-height: unset;
    }
  }

  &__price-container-future {
    background: rgba(255, 168, 0, 0.08);
  }
  &__addbag-icon {
    background: linear-gradient(90deg, #FA6239 -2.52%, #FE3930 92.95%);
    height: 0.933rem;
    width: 1.68rem;
    padding: 0.15rem 0.15rem 0.15rem 0.88rem;
    border-radius: 0 0.11333rem 0.11333rem 0;

    .product-item__add-bag_big {
      background: rgba(0, 0, 0, 0);
      color: #fff;
    }
  }

  &__addbag_future {
    background:linear-gradient(90deg, #FCD364 -2.52%, #FFA800 92.95%);
  }
  &__hook_count {
    width: 1.42475rem;
    height: 1.08848rem;
    color: #fff;
    font-size: 0.37333rem;
    font-weight: 700;
    text-align: center;
    line-height: 0.87rem;
    background: url("/pwa_dist/images/flash-sale/flash_hook_product_count-e7c9448133.png") no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    top: -0.1rem;
    right: 0.96rem;
    direction: ltr /* rtl:ignore */;
  }
  /* stylelint-disable-next-line selector-class-pattern */
  &__hook_count_future {
    background: url("/pwa_dist/images/flash-sale/flash_hook_product_count-orange-07d1fac346.png") no-repeat;
    background-size: cover;
    width: 1.42475rem;
    height: 1.08848rem;
  }
  &__addbag_soldout {
    background: linear-gradient(90deg, #FFA9A5 -2.52%, #FFC8B9 92.95%);
  }
}
</style>
