<script lang="jsx">
import { clickAddToBoardToast } from '../analysis'
import Vue from 'vue'

export default {
  name: 'ProductItemWishlistGroup',
  functional: true,
  props: {
    config: {
      type: Object,
      default: () => ( {} )
    },
    language: {
      type: Object,
      default: () => ( {} )
    },
    show: {
      type: Boolean,
      default: false
    },
    curData: {
      type: Object,
      default: () => ( {} )
    },
  },
  render(h, { props }) {
    // 添加至分组
    const addBoard = () => {
      Vue.prototype.$_WD({
        type: 6,
        params: {
          goodsIds: [props.curData.goods_id]
        }
      })

      let groupList = []
      try {
        groupList = JSON.parse(window.localStorage.getItem('groupList')) || []
      } catch(e) {
        console.log(e)
      }
      // 点击分组按钮埋点, code 兼容了新老商品项
      clickAddToBoardToast({
        code: props.config.code || props.config.ga?.code,
        groupList,
      })
    }

    return (
      // 加组按钮
      <div
        class={[
          'product-item__add-board',
          {
            'product-item__add-board_active': props.show,
          },
        ]}
        aria-hidden="true"
        vOn:click_stop={addBoard}
      >
        <i class="suiiconfont sui_icon_add_circle_18px"></i>
        { props.language.SHEIN_KEY_PWA_17403 }
      </div>
    )
  },
}
</script>

<style lang="less">
  .product-item {
    &__add-board {
      position: absolute;
      left: 0;
      bottom: -0.8533rem;
      transform: translateY(0);
      width: 100%;
      height: 0.8533rem;
      line-height: 0.8533rem;
      padding-left: 0.2135rem;
      padding-right: 0.1067rem * 4 + 0.64rem;
      .text-overflow();
      background: rgba(0, 0, 0, .8);
      color: #fff;
      transition: transform 0.5s;
      z-index: @zindex-out;
      /*rw:begin*/
      border-radius: 2px;
      &_active {
        transform: translateY(-0.8533rem);
      }
      [class*="iconfont"] {
        margin-right: 0.1067rem;
        vertical-align: middle;
        font-size: 18px;
      }
    }
  }
</style>
