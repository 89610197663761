<template>
  <div 
    v-if="show"
    class="product-item__feed-guide"
    @click.stop
  >
    <div class="product-item__feed-guide-cont">
      <i 
        class="suiiconfont sui_icon_more_20px"
        @click="hide"
      ></i>
      <div class="product-item__feed-guide-text">
        <p>{{ language.SHEIN_KEY_PWA_18974 }}</p>
        <span @click="hide">{{ language.SHEIN_KEY_PWA_19180 }}</span>
        <div></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    language: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      show: true
    }
  },
  mounted () {
    this.show = localStorage.getItem('pwa_feedback_guide') != 1
    localStorage.setItem('pwa_feedback_guide', 1)
    document.addEventListener('click', this.hide)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.hide)
  },
  methods: {
    hide () {
      this.show = false
    }
  }
}
</script>
<style lang="less">
.product-item {
  &__feed-guide {
    position: absolute;
    top: 0;
    left: 0/* rtl:ignore */;
    right: 0/* rtl:ignore */;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: @zindex-list-guide;
  }
  &__feed-guide-cont {
    position: absolute;
    top: 6.266667rem;
    right: 0;
    width: .533333rem;
    height: .533333rem;
    border-radius: 50%;
    background: white;
    .flexbox();
    align-items: center;
    justify-content: center;
    i {
      color: #222;
      .font-dpr(40px);
    }
  }
  &__feed-guide-text {
    position: absolute;
    bottom: .8rem;
    right: .106667rem;
    background: white;
    border-radius: .04rem;
    width: 4.306667rem;
    padding: .32rem;
    color: #666;
    .font-dpr(24px);
    /* rw:begin */
    padding-bottom: 0;
    p {
      margin-bottom: .32rem;
      .line-camp(9);
    }
    span {
      color: #222;
      .border-dpr(border, 2px, #222);
      padding: .133333rem .24rem;
      /* rw:begin */
      display: block;
      height: .96rem;
      line-height: .96rem;
      padding: 0;
      text-align: center;
      border: 0;
      .border-dpr(border-top, 2px, #E5E5E5);
      color: #FF696E;
      font-weight: bold;
    }
    div {
      position: absolute;
      right: .04rem;
      bottom: -.36rem;
      width: 0;
      height: 0;
      border: .16rem solid transparent;
      border-top: .24rem solid white;
    }
  }
}
</style>
