<script lang="jsx">
import ProductItemOverlay from './ProductItemOverlay'
import ProductItemOperationBtns from './ProductItemOperationBtns'

export default {
  name: 'SoldOut',
  functional: true,
  props: {
    curData: {
      type: Object,
      default: () => ( {} )
    },
    language: {
      type: Object,
      default: () => ( {} )
    },
    constantData: {
      type: Object,
      default: () => ( {} )
    },
    config: {
      type: Object,
      default: () => ( {} )
    },
    isSoldOut: {
      type: Boolean,
      default: false
    },
    showSimilarPopup: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: -1
    },
    showSelect: Boolean
  },
  render(h, { props, listeners }) {
    const isRW = props.constantData.IS_RW
    const newFlashSaleStyle = props.config.style?.discountLabel === 'flashSale'

    return (
      <ProductItemOverlay
        value={props.isSoldOut}
        close={false}
        vOn:click={props.showSelect ? listeners['select'] : listeners['clickItem']}
      >
        <div class="product-item__soldout-content">
          {
            newFlashSaleStyle ? (
              <i class={[
                'suiiconfont',
                {
                  'sui_icon_finished_20px': isRW,
                  'sui_icon_hourglass_24px': !isRW,
                },
              ]}></i>
            ) : (
              <i class={[
                'iconfont',
                {
                  'icon-category-': isRW,
                  'icon-SOLDOUT': !isRW,
                },
              ]}></i>
            )
          }
          <p>{ newFlashSaleStyle ? props.language.SHEIN_KEY_PWA_16808 : props.language.SHEIN_KEY_PWA_14987 }</p>
          {/* 操作按钮组合 */}
          {props.showSelect ? null : (
            <ProductItemOperationBtns
              cur-data={props.curData}
              language={props.language}
              config={props.config}
              index={props.index}
              is-sold-out={props.isSoldOut}
              show-similar-popup={props.showSimilarPopup}
              type="normalFlow"
              vOn:clickAddToBoard={listeners['clickAddToBoard']}
              vOn:clickDelete={listeners['clickDelete']}
            />
          )}
        </div>
      </ProductItemOverlay>
    )
  },
}
</script>

<style lang="less">
.product-item {
  &__soldout-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    color: #fff;
    text-align: center;
    z-index: @zindex-hack;
    [class*="iconfont"] {
      .font-dpr(72px);
    }
    p {
      .font-dpr(28px);
      font-weight: bold;
    }
  }
}
</style>
