<template>
  <div 
    ref="starLabelsCtn"
    class="product-item__star-labels"
    :class="[
      !hideLabels && hasUserBehaviorOrRankingList && 'product-item__star-labels_three',
      !hideLabels && !hasUserBehaviorOrRankingList && 'product-item__star-labels_two',
    ]"
    :style="goodsBottomStyle"
  >
    <!-- 星级评分 -->
    <ProductItemStarComment 
      :cur-data="curData"
      :show-star-rating="true"
    />

    <div 
      v-if="showLabels"
      ref="line"
      class="line"
    ></div>

    <!-- 用户评论关键词标签 -->
    <ProductItemUserCommentKeywordsLabel 
      v-if="userCommentKeywordsLabelLang"
      :user-comment-keywords-label-info="userCommentKeywordsLabelInfo"
    />
    <!-- 用户行为标签 -->
    <ProductItemUserBehaviorLabel 
      v-else-if="userBehaviorLabelLang"
      :user-behavior-label-info="userBehaviorLabelInfo"
    />
    <!-- 榜单 -->
    <ProductItemRankingList  
      v-else-if="showRankingList"
      :ranking-list="rankingList"
    />

    <template v-if="!hideLabels">      
      <!-- 新型闪购（新人，专属） -->
      <NormalGoodsLabel 
        v-if="showNewFlash"
        type="newFlash"
        :label-text="labelsShow.flashTypeText"
      />  
      <!-- 本地卖家 -->
      <NormalGoodsLabel 
        v-if="showLocalSeller"
        type="localSeller"
        :label-text="labelsShow.localMallName"
      />
      <!-- 快速发货 -->
      <NormalGoodsLabel 
        v-else-if="showQuickShip"
        html
        type="quickShip"
        :label-text="language.SHEIN_KEY_PWA_22276"
      />
      <!-- 减价（price cut） -->
      <NormalGoodsLabel 
        v-if="showPriceCut"
        type="priceCut"
        :label-text="language.SHEIN_KEY_PWA_18817"
      />
      <!-- promotion -->
      <NormalGoodsLabel 
        v-if="showPromotion"
        type="promotion"
        :label-text="labelsShow.promotionLabel"
      />
      <!-- 卖点 -->
      <template v-if="showSellingPointsOnLabel">
        <template v-for="(item, idx) in sellingPoints">
          <NormalGoodsLabel 
            v-if="item && finalSellingPointIndex[idx]"
            :key="item.tag_val_id"
            :type="config.showGreySellingPoint && !constantData.IS_RW ? 'newSellingpoint' : 'sellingpoint'"
            :class="['sellingPoint' + idx]"
            :label-text="item.tag_val_name_lang"
          />
        </template>
      </template>
      <!-- ccc-tsp角标 -->
      <NormalGoodsLabel 
        v-if="showCccTspBadge"
        type="cccTspLabel"
        :style="goodsBottomBadgeStyle"
        :label-text="labelsShow.cccTspBadges"
      />
      <!-- 剩余库存, 0-10(不含首尾) 例: only 2 left -->
      <NormalGoodsLabel 
        v-if="showStockLeft"
        type="stockleft"
        :label-text="labelsShow.stockLeft"
      />
    </template>
  </div>
</template>

<script>
import ProductItemStarComment from './ProductItemStarComment'
import ProductItemRankingList from './ProductItemRankingList'
import ProductItemUserBehaviorLabel from './ProductItemUserBehaviorLabel'
import ProductItemUserCommentKeywordsLabel from './ProductItemUserCommentKeywordsLabel.vue'
import NormalGoodsLabel from './UI/NormalGoodsLabel.vue'
import labelsCalcMixin from '../js/labelsCalcMixin'

export default {
  name: 'ProductItemStarRatingWithLabels',
  components: {
    ProductItemStarComment,
    ProductItemRankingList,
    ProductItemUserBehaviorLabel,
    ProductItemUserCommentKeywordsLabel,
    NormalGoodsLabel
  },
  mixins: [labelsCalcMixin],
  props: {
    config: {
      type: Object,
      default: () => ({})
    },
    curData: {
      type: Object,
      default: () => ({})
    },
    pretreatInfo: {
      type: Object,
      default: () => ({})
    },
    column: {
      type: [Number, String],
      default: 2
    },
    showRankingListIn: {
      type: Boolean,
      default: false
    },
    constantData: {
      type: Object,
      default: () => ({})
    },
    rankingList: {
      type: Object,
      default: () => ({})
    },
    userBehaviorLabelInfo: {
      type: Object,
      default: () => ({})
    },
    userBehaviorLabelLang: {
      type: String,
      default: ''
    },
    userCommentKeywordsLabelInfo: {
      type: Object,
      default: () => ({})
    },
    userCommentKeywordsLabelLang: {
      type: String,
      default: ''
    },
    labelsShow: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    showSellingPointsOnLabel: {
      type: Boolean,
      default: false
    },
    goodsBottomBadgeStyle: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      default: 0
    },
    hideLabels: {
      type: Boolean,
      default: false
    },
    isSoldOut: {
      type: Boolean,
      default: false
    },
    itemWidth: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      userBehaviorLabelWithinWidth: true,
      rankingListLabelWithinWidth: true,
      newFlashLabelWithinWidth: true,
      priceCutWithinWidth: true,
      promotionWithinWidth: true,
      cccTspBadgeWithinWidth: true,
      localSellerWithinWidth: true,
      stockLeftWithinWidth: true,
      isStarRatingWithLabels: true
    }
  },
  computed: {
    showNewFlash() {
      return this.labelsShow.flashTypeText && this.newFlashLabelWithinWidth
    },
    showLocalSeller() {
      return this.labelsShow.localMallName && this.localSellerWithinWidth
    },
    showQuickShip() {
      console.log(this.labelsShow.quickShip, this.localSellerWithinWidth)
      return this.labelsShow.quickShip && this.localSellerWithinWidth
    },
    showPriceCut() {
      return this.labelsShow.priceCut && this.priceCutWithinWidth
    },
    showPromotion() {
      return this.labelsShow.promotionLabel && this.promotionWithinWidth
    },
    // 卖点
    sellingPoints() {
      return this.labelsShow?.sellingPoints || []
    },
    showCccTspBadge() {
      return this.labelsShow.cccTspBadges && this.cccTspBadgeWithinWidth
    },
    // 仅romwe
    showStockLeft() {
      return this.labelsShow.stockLeft && this.stockLeftWithinWidth
    },
    showRankingList() {
      return this.rankingList && Object.keys(this.rankingList).length && this.showRankingListIn
    },
  },
}
</script>

<style lang="less">
.product-item {
  &__star-labels {
    .flexbox();
    .align-center();
    flex-wrap: wrap;
    max-height: 0.5334rem;
    &_two {
      max-height: 1.0668rem;
    }
    &_three{
      max-height: 1.6002rem;
    }
    >div:not(:last-of-type) {
      margin-right: 8px;
    }
    >div.product-item__comment,
    >div.product-item__user-behavior,
    >div.product-item__ranking-list,
    >div.product-item__user-commentLabel {
      margin-right: 0;
    }
    >div.product-item__user-behavior {
      padding-right: 8px;
    }
    >div.line {
      width: 1px;
      height: 10px;
      margin: 0.1067rem 4px 0;
      background-color: @sui_color_gray_weak1;
      flex-shrink: 0;
    }
  }
}
</style>
