class addEvent {
  constructor() {
    this.initTouch = false
  }

  static initEvent(event, that, eventCallback) {
    const cb = (e) => eventCallback(e)
    document.addEventListener(event, cb, false)

    that.$once && that.$once('hook:beforeDestroy', () => document.removeEventListener(event, cb))
  }

  add(event, that, eventCallback) {
    if (!this.initTouch) {
      addEvent.initEvent(event, that, eventCallback)
      this.initTouch = true
    }
  }
}

export const addEventInstance = new addEvent()
