<script lang="jsx">
export default {
  name: 'PriceReductionAmountLabel',
  functional: true,
  props: {
    // 带币种符号的金额
    amountWithSymbol: {
      type: String,
      default: ''
    },
    // 降价金额前的img
    img: {
      type: String,
      default: '',
    },
    // 降价金额前的icon
    icon: {
      type: String,
      default: 'sui_icon_lowprice_12px',
    },
    // 降价金额前的icon的颜色
    iconColor: {
      type: String,
      default: '#FACF19'
    },
    // 使用圆角
    radius: {
      type: Boolean,
      default: true
    },
    constantData: {
      type: Object,
      default: () => ({})
    },
  },
  render(h, obj) {
    const { amountWithSymbol, img, icon, iconColor, radius, constantData } = obj.props || {}
    const { SiteUID } = constantData
    const isArSite = SiteUID === 'pwar'
    let priceText = '-' + amountWithSymbol
    let background = 'linear-gradient(270.05deg, rgba(250, 207, 25, 0.8) -0.61%, #FF5626 100.62%)'
    if (isArSite) {
      priceText = amountWithSymbol + '-'
    }
    const useImage = Boolean(img)
    const externalClass = obj?.data?.class || ''
    const prefix = 
      useImage
        ? (<div class="price-reduction-wrap__img-wrap">
          <img src={img} />
        </div>)
        : (<div class="price-reduction-wrap__icon-wrap" style={{ color: iconColor }}>
          <i class={['suiiconfont', icon]}></i>
        </div>)
    
    const labelStyle = {
      background
    }

    const label = (
      <div class="price-reduction-container">
        <div
          class={[
            'price-reduction-wrap',
            {
              'price-reduction-wrap-radius': !!radius,
            },
            externalClass
          ]}
          style={labelStyle}
        >
          {!isArSite && prefix}
          <span class="price-reduction-wrap__amount">
            {priceText}
          </span>
          {isArSite && prefix}
        </div>
      </div>
    )
    return label
  },
}
</script>

<style lang="less">
.price-reduction-container {
  height: 0.64rem;
  display: flex;
  align-items: center;
  margin-left: 0.1067rem;
}
.price-reduction-wrap {
  display: flex;
  align-items: center;
  line-height: 0.32rem;
  height: 0.4266666666666667rem;
  padding: 0.05333333333333334rem 0.10666666666666667rem;
  /* stylelint-disable-next-line selector-class-pattern,selector-nested-pattern */
  &-radius {
    border-radius: 0.5333333333333333rem;
  }
  &__icon-wrap {
    margin-right: 0.10666666666666667rem /* rtl: ignore */; 
    [class*=sui_icon] {
      line-height: 0.32rem;
    }
  }
  &__amount {
    color: #fff;
    font-family: SF-UI-Text-Bold;
    font-size: 0.26666666666666666rem;
  }
}
</style>
