<script lang="jsx">
import { template } from '@shein/common-function'
import ProudctItemReducePrice from './ProductItemReducePrice'
import ProductItemSaleAttr from './ProductItemSaleAttr'
import ProductItemNormalLabels from './ProductItemNormalLabels'
import ProductItemUserBehaviorLabel from './ProductItemUserBehaviorLabel'
import ProductItemUserCommentKeywordsLabel from './ProductItemUserCommentKeywordsLabel.vue'
import SearchFilterLabel from './SearchFilterLabel'

export default {
  name: 'ProductItemBottomLabels',
  functional: true,
  render(h, { props, listeners }) {
    const { curData, config, language, index, constantData, createComp = true, isSoldOut, multiColumn, showOneLine, userBehaviorLabelInfo, userBehaviorLabelLang, userCommentKeywordsLabelInfo, userCommentKeywordsLabelLang, showSellingPointsOnLabel, labelsShow, goodsBottomBadgeStyle, hasStarRating, showColorSwiper, pretreatInfo, itemWidth, hasNormalLabels } = props || {}
    // 降价标签
    const reducePriceLabel = pretreatInfo?.reducePrice?.label
    const showReducePrice = config.showReducePrice && reducePriceLabel
    // 销售属性
    const saleAttrLabel = pretreatInfo?.saleAttr?.label
    const showSaleAttr = config.showSaleAttr && saleAttrLabel
    // 分布式筛选
    const searchFilters = pretreatInfo?.searchFilters || []
    // 底部普通标签
    const hideNormalLabels = hasStarRating && !showColorSwiper
    // 用户行为标签(旧的，仅推荐有接入)
    const handlerUserActText = (n, text, showUserActLabel) => {
      listeners?.['showUserAct']?.(showUserActLabel)
      return template(n + 'k+', text?.replace('num', '0'))
    }
    const userActTip = (() => {
      if (!curData.tspLabels) return ''
      const { c7d_click_pv, c7d_wishlist_uv, c7d_sale_cnt, eval_cnt } = curData.tspLabels
      const { SHEIN_KEY_PWA_20804, SHEIN_KEY_PWA_20801, SHEIN_KEY_PWA_20802, SHEIN_KEY_PWA_20803 } = language
      // 用户标签的展示优先级
      // eval_cnt > c7d_click_pv > c7d_wishlist_uv > c7d_sale_cnt

      // 历史评论数>=10k可展示标签，n向下取整
      if (eval_cnt && Math.floor(eval_cnt / 1000) >= 10) {
        return handlerUserActText(Math.floor(eval_cnt / 1000), SHEIN_KEY_PWA_20804, `eval_cnt_${Math.floor(eval_cnt / 1000)}k`)
      }

      // 7天浏览>=100k可展示标签，n向下取整
      if (c7d_click_pv && Math.floor(c7d_click_pv / 1000) >= 100) {
        return handlerUserActText(Math.floor(c7d_click_pv / 1000), SHEIN_KEY_PWA_20801, `c7d_click_pv_${Math.floor(c7d_click_pv / 1000)}k`)
      }

      // 7天收藏>=2k可展示标签，n向下取整
      if (c7d_wishlist_uv && Math.floor(c7d_wishlist_uv / 1000) >= 2) {
        return handlerUserActText(Math.floor(c7d_wishlist_uv / 1000), SHEIN_KEY_PWA_20802, `c7d_wishlist_uv_${Math.floor(c7d_wishlist_uv / 1000)}k`)
      }

      // 7天购买>=1k可展示标签，n向下取整
      if (c7d_sale_cnt && Math.floor(c7d_sale_cnt / 1000) >= 1) {
        return handlerUserActText(Math.floor(c7d_sale_cnt / 1000), SHEIN_KEY_PWA_20803, `c7d_sale_cnt_${Math.floor(c7d_sale_cnt / 1000)}k`)
      }

      return ''
    })()
    // 普通标签
    const showNormalLabels = !hideNormalLabels && createComp && (hasNormalLabels || userActTip)

    return (
      <div>
        {/* 降价标签 例：US$2 cheaper than added */}
        {
          showReducePrice 
            ? ( <ProudctItemReducePrice reduce-price-label={reducePriceLabel} /> ) 
            : null
        }
        {/* 收藏属性 */}
        {
          showSaleAttr 
            ? ( <ProductItemSaleAttr sale-attr-label={saleAttrLabel} /> )
            : null
        }
        {/* 商品底部普通标签 */}
        {
          showNormalLabels
            ? (
              <ProductItemNormalLabels
                config={config}
                pretreat-info={pretreatInfo}
                language={language}
                is-sold-out={isSoldOut}
                constant-data={constantData}
                cur-data={curData}
                multi-column={multiColumn}
                show-one-line={showOneLine}
                index={index}
                show-selling-points-on-label={showSellingPointsOnLabel}
                labels-show={labelsShow}
                goods-bottom-badge-style={goodsBottomBadgeStyle}
                hide-labels={hideNormalLabels}
                item-width={itemWidth}
                user-act-tip={userActTip}
                vOn:exposeSellingPoint={listeners['exposeSellingPoint']}
                vOn:showCccTspBadge={listeners['showCccTspBadge']}
                vOn:showUserAct={listeners['showUserAct']}
                vOn:showQuickShip={listeners['showQuickShip']}
              />
            ) : null
        }
        {/* 商品评论关键词标签 */}
        {
          !hasStarRating && userCommentKeywordsLabelLang ? (
            <ProductItemUserCommentKeywordsLabel 
              user-comment-keywords-label-info={userCommentKeywordsLabelInfo}
            />
          ) : null
        }
        {/* 商品用户行为标签 */}
        {
          !hasStarRating && userBehaviorLabelLang 
            ? <ProductItemUserBehaviorLabel user-behavior-label-info={userBehaviorLabelInfo} />
            : null
        }
        {/* 分布式筛选 */}
        {
          curData && pretreatInfo && createComp && searchFilters.length
            ? (
              <SearchFilterLabel 
                item-width={itemWidth}
                index={index}
                pretreat-info={pretreatInfo}
                is-sold-out={isSoldOut}
                search-filters={searchFilters}
                vOn:clickSearchFilter={listeners['clickSearchFilter']}
                vOn:showSearchFilter={listeners['showSearchFilter']}
              />
            ) : null
        }
      </div>
    )
  }
}
</script>
