<script lang="jsx">
export default {
  name: 'ImtBottomLabel',
  functional: true,
  props: {
    type: {
      type: Array,
      default: () => []
    },
    center: {
      type: Boolean,
      default: false
    },
    labelIcon: {
      type: String,
      default: ''
    },
    labelText: {
      type: String,
      default: ''
    },
    lineClamp: {
      type: Number,
      default: 1
    }
  },
  render(h, { props }) {
    return props.labelText ? (
      <div
        class={[
          'product-item__imgbottom-label',
          {
            'product-item__imgbottom-label_orange': props.type[0] === 'orange',
            'product-item__imgbottom-label_black': props.type[0] === 'black',
            'product-item__imgbottom-label_center': props.center,
          },
        ]}
      >
        {props.labelIcon ? <i class={['suiiconfont', props.labelIcon]}></i> : null}
        <span
          class={[
            'imgbottom-label__text',
            {
              'imgbottom-label__text_two': props.lineClamp === 2,
              'imgbottom-label__text_12PX': props.type[1] === '12PX',
              'imgbottom-label__text_10PX': props.type[1] === '10PX',
            },
          ]}
        >
          {props.labelText}
        </span>
      </div>
    ) : null
  },
}
</script>

<style lang="less">
.product-item {
  &__imgbottom-label {
    .flexbox();
    .align-center();
    width: 100%;
    line-height: 0.32rem;
    color: #fff;
    padding: 0.08rem 0.1067rem;
    &_orange {
      background: @sui_color_promo;  
      opacity: 0.88;
    }
    &_black {
      background: rgba(65, 63, 63, .8);
    }
    &_center {
      .pack-center();
    }
    .suiiconfont {
      margin-right: 0.1067rem;
    }
    .imgbottom-label {
      &__text {
        .line-camp(1);
        display: inline-block;
        white-space: nowrap;

        &_two {
          .line-camp(2);
        }
        &_12PX {
          .font-dpr(24px);
        }
        &_10PX {
          .font-dpr(20px);
        }
      }
    }
  }
}
</style>
