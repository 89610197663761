<script lang="jsx">
import { CropImageContainer } from '../../../CropImageContainer/index.js'

export default {
  name: 'ProductItemImg',
  functional: true,
  inject: {
    isProductPage: {
      from: 'isProductPage',
      default: false,
    },
    getIsSupportCropImage: {
      from: 'getIsSupportCropImage',
      default: () => false,
    }
  },
  render(h, { props, injections }) {
    const { constantData, relateColorInfo, lcp, mainImg, goodsImg, goodsName, cropRate, index, imgDesignWidth = 0, inProductList, autoCropImage, cropImageContainerBackground, compSrc } = props || {}
    const { isProductPage = false, getIsSupportCropImage } = injections || {}

    const isSupportCropImage = getIsSupportCropImage && getIsSupportCropImage()
    const { LAZY_IMG_SOLID_COLOR } = constantData || {}

    // 首屏（转场、ssr）前6张，直接加载，不使用懒加载
    let isFirstPageProducts = index < 6 && isProductPage
    
    const isUseOriginUrl = lcp || isFirstPageProducts

    const className = []
    if (isFirstPageProducts) {
      className.push('product-item--bg-gray')
    }

    // 默认商品项为 3:4 补图逻辑，在开启为 1:1 补图逻辑时，需要为商品项传递参数 autoCropImage
    const mainImgWithLazy =
              <CropImageContainer
                class={className}
                isSupportCropImage={isSupportCropImage}
                imgSrc={mainImg}
                containerBackground={cropImageContainerBackground}
                fixedRatio={cropRate ?  cropRate : autoCropImage ? '' : '3-4'}
                transformImgProps={{
                  imgClassName: [
                    'fsp-element product-item__main-img',
                    {
                      'product-item__main-img_minHeight': inProductList,
                    },
                    {
                      [`fsp__${compSrc}`]: !!compSrc
                    }
                  ],
                  isFirstPage: isUseOriginUrl,
                  useOriginImg: false,
                  usePicture: false,
                  loadImg: LAZY_IMG_SOLID_COLOR,
                  imgDesignWidth,
                  alt: goodsName,
                  fetchpriority: 'high',
                }}
              />

    return (
      <div>
        {
          !relateColorInfo 
            ? mainImgWithLazy
            : (
              // 色块切换，首图无需懒加载
              <CropImageContainer
                class={className}
                isSupportCropImage={isSupportCropImage}
                imgSrc={goodsImg}
                containerBackground={cropImageContainerBackground}
                fixedRatio={cropRate ?  cropRate : autoCropImage ? '' : '3-4'}
                transformImgProps={{
                  imgClassName: [
                    'product-item__main-img',
                    {
                      'product-item__main-img_crop': cropRate
                    },
                    {
                      [`fsp__${compSrc}`]: !!compSrc
                    }
                  ],
                  loadImg: LAZY_IMG_SOLID_COLOR,
                  isFirstPage: true,
                  alt: goodsName,
                }}
              />
            )
        }
      </div>
    )
  }
}
</script>

<style lang="less">
/* stylelint-disable-next-line */
.product-item--bg-gray {
  background-color: @sui_color_gray_weak1a;
}
</style>
