var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item__colors",class:{
    'product-item__colors_small-gap': _vm.elSmallGap
  }},[_c('ClientOnly',[_c('div',{staticClass:"product-item__colors-wrapper",class:{
        'product-item__colors-wrapper_col-2': _vm.column == 2,
        'product-item__colors-wrapper_col-23': _vm.column == 2.3,
        'product-item__colors-wrapper_col-29': _vm.column == 2.9,
        'product-item__colors-wrapper_col-3': _vm.column == 3,
        'product-item__colors-wrapper_col-3l': _vm.column == '3l',
        'product-item__colors-wrapper_col-33': _vm.column == 3.3,
        'product-item__colors-wrapper_col-33l': _vm.column == '3.3l'
      }},[_c('ScrollBar',{ref:"scrollBar",attrs:{"right-show-num":2,"reverse":_vm.cssRight},on:{"checkEnd":_vm.handleCheckEnd},model:{value:(_vm.selectIndex),callback:function ($$v) {_vm.selectIndex=$$v},expression:"selectIndex"}},_vm._l((_vm.item.relatedColor),function(colorItem,colorIndex){return _c('ScrollBarItem',{key:colorIndex},[_c('s-radio-color',{attrs:{"lazy-load":!!colorItem.goods_color_image,"lazy-load-engine":colorItem.goods_color_image ? 'lazyload' : '',"label":colorIndex,"color-image":colorItem.goods_color_image ? _vm.transformImg({ img: colorItem.goods_color_image }) : '',"size":"radio36","margin-r":colorIndex !== _vm.item.relatedColor.length - 1 ? '14px' : '',"saleout":colorItem.stock == 0 && _vm.config.showColorSellOut,"role":"radio","aria-checked":colorItem.goods_id == _vm.curData.goods_id,"aria-label":_vm.item.goods_name,"tabindex":"0"},model:{value:(_vm.selectIndex),callback:function ($$v) {_vm.selectIndex=$$v},expression:"selectIndex"}})],1)}),1),_vm._v(" "),(_vm.showNextBtn && !_vm.isEnd)?_c('span',{staticClass:"colors-wrapper__next-btn",on:{"click":function($event){$event.stopPropagation();return _vm.scrollToNext.apply(null, arguments)}}},[_c('i',{staticClass:"suiiconfont sui_icon_more_right_16px"})]):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }