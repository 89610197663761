import { daEventCenter } from 'public/src/services/eventCenter'

// 商品项点击埋点（配置了delayClickAnalysis时触发）
const clickProductItem = ({ itemDAEventClickId, target }) => {
  daEventCenter.triggerNotice({
    daId: itemDAEventClickId,
    target
  })
}
// 商品项在切色块时曝光
const exposeProductItem = ({ itemDAEventExposeId, target }) => {
  daEventCenter.triggerNotice({
    daId: itemDAEventExposeId,
    target
  })
}

// 点击find similar按钮
const clickFindSimilarBtn = (extraData) => {
  extraData.isOutOfStock = extraData.isSoldOut
  extraData.findsimilar_method = extraData.isSoldOut ? 'SoldOut' : 'ClickMore'
  daEventCenter.triggerNotice({
    daId: '1-7-1-3',
    extraData
  })
}

// 曝光find similar按钮
const exposeFindSimilarBtn = (extraData) => {
  daEventCenter.triggerNotice({
    daId: '2-3-16',
    extraData
  })
}

// 点击delete按钮
const clickDeleteBtn = (extraData) => {
  extraData.item_sold_out = extraData.isSoldOut
  extraData.delete_method = extraData.isSoldOut ? 'SoldOut' : 'ClickMore'
  daEventCenter.triggerNotice({
    daId: '1-10-4-9',
    extraData
  })
}

// 收藏
const clickAddWish = ({ isAdd, curData, addWishResult, config, target, index, moduleFrom }) => {
  const { goods_id, goods_sn, mall_code } = curData || {}
  const { sa, ga, itemDAEventClickId, itemWishDAEventClickId, showQuickShip } = config || {}
  let postData = {
    action: isAdd, // 选中发1, 取消发0
    goods_id,
    sku: goods_sn,
  }
  if (isAdd) {
    postData = {
      ...postData,
      detail: curData,
      index,
      recommendType: moduleFrom == 'cccRecommend' ? 2 : moduleFrom == 'recommend' ? 1 : 0
    }
    if (showQuickShip) {
      postData.quickShip = curData.quickship
    }
  }

  daEventCenter.triggerNotice({
    daId: itemWishDAEventClickId || '1-8-1-2',
    target,
    extraData: {
      code: ga?.code || '',
      mall_code: mall_code || '',
      daEventId: itemDAEventClickId,
      result: addWishResult,
      postData,
      from: sa?.activity_from || ''
    }
  })
}

// 点击加车按钮
const clickAddBagAna = ({ target, extraData }) => {
  daEventCenter.triggerNotice({
    daId: '2-3-5',
    target,
    extraData
  })
}

// 关闭overlay
const clickCloseOverlay = ({ isSoldOut }) => {
  daEventCenter.triggerNotice({
    daId: '1-10-4-7',
    extraData: {
      item_sold_out: isSoldOut
    }
  })
}

// 曝光add to board按钮
const exposeAddToBoardBtn = ({ groupListLen }) => {
  daEventCenter.triggerNotice({
    daId: '1-10-2-2',
    extraData: {
      board_count: groupListLen ? 1 : 0
    }
  })
}

// 曝光负反馈弹窗
const exposeFeedback = ({ goods_id, feedback, feedbackType, goodsLabel }) => {
  daEventCenter.triggerNotice({
    daId: '2-3-9',
    extraData: {
      goods_id,
      entry_from: feedback.pageStyleKey,
      feedback_value: feedbackType.join('|'),
      // abtest,
      goods_label: goodsLabel || ''
    }
  })
}

// 点击负反馈选项
const clickFeedback = ({ goods_id, feedback, index, id, goodsLabel }) => {
  daEventCenter.triggerNotice({
    daId: '2-3-10',
    extraData: {
      goods_id,
      entry_from: feedback.pageStyleKey,
      feedback_value: index + 1 + '_' + id,
      // abtest,
      goods_label: goodsLabel || ''
    }
  })
}

// 负反馈点击找相似
const clickFindSimilarOnFeedback = ({ goods_id, feedback, abtest }) => {
  daEventCenter.triggerNotice({
    daId: '2-3-11',
    extraData: {
      goods_id,
      entry_from: feedback.pageStyleKey,
      similar_from: 'expand',
      abtest
    }
  })
}

// 点击负反馈选项，曝光找相似
const exposeFindSimilarByFeeback = ({ goods_id, feedback, abtest }) => {
  daEventCenter.triggerNotice({
    daId: '2-3-12',
    extraData: {
      goods_id,
      entry_from: feedback.pageStyleKey,
      similar_from: 'expand',
      abtest
    }
  })
}

// 点击add to board（收藏后，从底部弹起add to board浮窗）
const clickAddToBoardToast = ({ code, groupList }) => {
  daEventCenter.triggerNotice({
    daId: '1-10-2-3',
    extraData: {
      code: code || '',
      board_count: groupList.length ? 1 : 0
    }
  })
}

// 点击分布式筛选
const clickSearchFilterAna = ({ id, itemIndex, filterIndex, dataSource }) => {
  daEventCenter.triggerNotice({
    daId: '2-3-17',
    extraData: {
      id,
      itemIndex,
      filterIndex,
      dataSource
    }
  })
}

// 曝光分布式筛选
const exposeSearchFilterAna = (searchFilter, target) => {
  daEventCenter.triggerNotice({
    daId: '2-3-18',
    extraData: {
      searchFilter,
      target
    }
  })
}

export {
  clickProductItem,
  exposeProductItem,
  clickFindSimilarBtn,
  exposeFindSimilarBtn,
  clickDeleteBtn,
  clickAddWish,
  clickAddBagAna,
  clickCloseOverlay,
  exposeAddToBoardBtn,
  exposeFeedback,
  clickFeedback,
  clickFindSimilarOnFeedback,
  exposeFindSimilarByFeeback,
  clickAddToBoardToast,
  clickSearchFilterAna,
  exposeSearchFilterAna,
}
