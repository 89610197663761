<script lang="jsx">
export default {
  name: 'ProductItemGoodsName',
  functional: true,
  props: {
    language: {
      type: Object,
      default: () => ( {} )
    },
    showHotTag: {
      type: Number, // TSP标签服务获取是否展示hot标
      default: 0 
    },
    goodsName: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      default: () => ({})
    },
    frontTitle: {
      type: Object,
      default: () => ({})
    },
    elSmallGap: {
      type: Boolean,
      default: false
    },
    showNormalPrice: {
      type: Boolean,
      default: false
    },
  },
  render(h, { props, data }) {
    return (
      <div
        class={[
          'product-item__name-wrapper',
          {
            'product-item__name-wrapper_small-gap': props.elSmallGap,
            'product-item__name-wrapper_big-cart': props.elSmallGap && props.config.showAddBagAfterGoodsPrice,
            'product-item__name-wrapper_no-gap': !props.showNormalPrice,
          },
          data.class,
          data.staticClass,
        ]}
      >
        {/* 标题 */}
        <div
          class={[
            'product-item__goods-name',
            {
              'product-item__goods-name_twoline': props.config.showTitleTwoLine,
            }
          ]}
          tabindex="0"
          aria-label={props.goodsName}
        >
          {/* ccc-tsp角标 */}
          {props.frontTitle ? <label class="product-item__title-badge">{ props.frontTitle.text }</label> : null}
          {/* 新增ccc-new标签 */}
          {!props.frontTitle && props.showHotTag === 2 ? <label class="product-item__title-badge product-item__hot-badge">{ props.language.SHEIN_KEY_PWA_25731 || 'Hot' }</label> : null}
          {/* 商品名字 */}
          { props.goodsName }
        </div>
      </div>
    )
  },
}
</script>

<style lang="less">
.product-item {
  &__name-wrapper {
    margin-top: 0.2133rem;
    &_small-gap {
      margin-top: 0.1067rem
    }
    &_big-cart {
      margin-top: 0.15rem;
    }
    &_no-gap {
      margin-top: 0;
    }
  }
  &__title-badge {
    .font-dpr(20px);
    display: inline-block;
    padding: 0 3px;
    height: 0.3733rem;
    line-height: 0.3733rem;
    background: #EDFFEE;
    color: #3CBD45;
    font-weight: bold;
    margin-right: 0.1067rem;
  }
  &__hot-badge {
    background: #FA6338;
    color:#FFF;
  }
  &__goods-name {
    .line-camp(1);
    .font-dpr(24px);
    line-height: 0.3733rem;
    &_twoline {
      .line-camp(2);
    }
  }
}
</style>
