<template>
  <!-- 操作按钮组合 1. Add to Board 2. Find Similar 3. Delete -->
  <div 
    class="product-item__operation-btns"
    :class="{
      'product-item__operation-btns_position': type == 'positionFlow',
      'product-item__operation-btns_normal': type == 'normalFlow'
    }"
  >
    <s-button-group :width="3.88">
      <s-button-group-item
        v-if="!isSoldOut && (config.showAddToBoardBtn || config.showAddToBoardBtnOnSoldOut)"
        :type="['H56PX']"
        class="operation-btns__add-board"
        @click.stop="clickAddToBoard"
      >
        {{ language.SHEIN_KEY_PWA_19489 }}
      </s-button-group-item>
      <s-button-group-item 
        v-if="(config.showFindSimilarBtn || config.showFindSimilarBtnOnSoldOut) && !showSimilarPopup "
        :type="['H56PX']"
        class="operation-btns__find-similar j-item-find-similar"
        @click.stop="clickFindSimilar"
      >
        {{ language.SHEIN_KEY_PWA_16789 }}
      </s-button-group-item>
      <s-button-group-item 
        v-if="config.showDeleteBtn || config.showDeleteBtnOnSoldOut"
        :type="['primary', 'H56PX']"
        class="operation-btns__delete"
        @click.stop="clickDelete"
      >
        {{ language.SHEIN_KEY_PWA_15198 }}
      </s-button-group-item>
    </s-button-group>
  </div>
</template>

<script>
import { ButtonGroup as SButtonGroup, ButtonGroupItem as SButtonGroupItem } from '@shein/sui-mobile'
import SimilarInstance from 'public/src/pages/common/similar_product_modal'
import { exposeFindSimilarBtn, clickFindSimilarBtn, clickDeleteBtn } from '../analysis'

export default {
  name: 'ProductItemOperationBtns',
  components: {
    SButtonGroup,
    SButtonGroupItem
  },
  props: {
    from: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'positionFlow'
    },
    language: {
      type: Object,
      default: () => ( {} )
    },
    config: {
      type: Object,
      default: () => ( {} )
    },
    index: {
      type: Number,
      default: -1
    },
    curOperateIndex: {
      type: Number,
      default: -1
    },
    curData: {
      type: Object,
      default: () => ( {} )
    },
    isSoldOut: {
      type: Boolean,
      default: false
    },
    showSimilarPopup: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    show: {
      handler (val) {
        if (val && this.from == 'operationPanel') {
          const { activity_from } = this.config?.sa || {}
          const { code } = this.config?.ga || {}
          // 找相似按钮曝光
          if (this.config.showFindSimilarBtn) {
            exposeFindSimilarBtn({ ...this.curData, activity_from, isSoldOut: this.isSoldOut, code })
          }
        }
      },
      immediate: true
    },
  },
  methods: {
    clickAddToBoard () {
      this.$emit('clickAddToBoard', this.curData)
    },
    clickFindSimilar () {
      // 1. 弹出找相似弹窗
      SimilarInstance.showModal(this.curData, {
        similarFrom: this.config.itemDAEventClickId == '2-4-1' // 2-4-1表示推荐
          ? 'expand' 
          : this.isSoldOut ? 'out_of_stock' : 'other'
      })
      // 2. 上报当前找相似的点击事件
      clickFindSimilarBtn({ ...this.curData, daEventId: this.config.itemDAEventClickId, activeFrom: this.config.sa?.activity_from, isSoldOut: Number(this.isSoldOut), })
    },
    clickDelete () {
      const { activity_from } = this.config?.sa || {}
      clickDeleteBtn({ ...this.curData, activity_from, isSoldOut: Number(this.isSoldOut) })
      this.$emit('clickDelete', {
        ...this.curData,
        index: this.index,
        activity_from,
        isSoldOut: Number(this.isSoldOut),
        delSucc: () => {
          this.$set(this.curData, 'wishStatus', false)
        }
      })
    }
  }
}
</script>

<style lang="less">
.product-item {
  &__operation-btns {
    .S-button.S-button__H56PX {
      .font-dpr(24px);
      font-weight: normal;
    }
    &_position {
      position: absolute;
      left: 50%;
      bottom: 0.8533rem;
      transform: translateX(-50%);
    }
    &_normal {
      margin-top: 0.72rem;
    }
    .operation-btns {
      &__add-board {
        .S-button {
          border: 1px solid #fff;
          & when (@IS_RW) {
            color: #222;
          }
        }
      }
      &__find-similar {
        .S-button {
          border: 1px solid #fff;
          & when (@IS_RW) {
            color: #222;
          }
        }
      }
      &__delete {
        .S-button {
          background: rgba(0, 0, 0, .8);
          color: #fff;
          & when (@IS_RW) {
            background: none;
            border: 1px solid #fff;
          }
        }
      }
    }
  }
}
</style>
