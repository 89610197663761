<script lang="jsx">
export default {
  name: 'ProductItemSaleAttr',
  functional: true,
  render(h, { props, data }) {
    return (
      <div 
        class={[
          'product-item__sale-attr',
          data.class,
          data.staticClass,
        ]}
      >
        { props.saleAttrLabel }
      </div>
    )
  }
}
</script>

<style lang="less">
.product-item {
  &__sale-attr {
    .font-dpr(20px);
    line-height: 1.2;
    color: @sui_color_gray_dark3;
    margin-top: 0.0533rem;
  }
}
</style>
