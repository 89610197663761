<template>
  <div 
    class="product-item__colors"
    :class="{
      'product-item__colors_small-gap': elSmallGap
    }"
  >
    <ClientOnly>
      <div
        class="product-item__colors-wrapper"
        :class="{
          'product-item__colors-wrapper_col-2': column == 2,
          'product-item__colors-wrapper_col-23': column == 2.3,
          'product-item__colors-wrapper_col-29': column == 2.9,
          'product-item__colors-wrapper_col-3': column == 3,
          'product-item__colors-wrapper_col-3l': column == '3l',
          'product-item__colors-wrapper_col-33': column == 3.3,
          'product-item__colors-wrapper_col-33l': column == '3.3l'
        }"
      >
        <ScrollBar
          ref="scrollBar"
          v-model="selectIndex"
          :right-show-num="2"
          :reverse="cssRight"
          @checkEnd="handleCheckEnd"
        >
          <ScrollBarItem
            v-for="(colorItem, colorIndex) in item.relatedColor"
            :key="colorIndex"
          >
            <s-radio-color
              v-model="selectIndex"
              :lazy-load="!!colorItem.goods_color_image"
              :lazy-load-engine="colorItem.goods_color_image ? 'lazyload' : ''"
              :label="colorIndex"
              :color-image="colorItem.goods_color_image ? transformImg({ img: colorItem.goods_color_image }) : ''"
              size="radio36"
              :margin-r="colorIndex !== item.relatedColor.length - 1 ? '14px' : ''"
              :saleout="colorItem.stock == 0 && config.showColorSellOut"
              role="radio"
              :aria-checked="colorItem.goods_id == curData.goods_id"
              :aria-label="item.goods_name"
              tabindex="0"
            />
          </ScrollBarItem>
        </ScrollBar>
        <span
          v-if="showNextBtn && !isEnd"
          class="colors-wrapper__next-btn"
          @click.stop="scrollToNext"
        >
          <i
            class="suiiconfont sui_icon_more_right_16px"
          ></i>
        </span>
      </div>
    </ClientOnly>
  </div>
</template>

<script>
import ClientOnly from 'vue-client-only'
import { RadioColor as SRadioColor } from '@shein/sui-mobile'
import ScrollBar from 'public/src/pages/components/scrollBar/ScrollBar'
import ScrollBarItem from 'public/src/pages/components/scrollBar/ScrollBarItem'
import { transformImg } from '@shein/common-function'
import { expose } from 'public/src/pages/common/analysis/directive.js'

export default {
  name: 'ColorSwiper',
  components: {
    SRadioColor,
    ScrollBar,
    ScrollBarItem,
    ClientOnly
  },
  directives: { expose },
  props: {
    // 首次进入的商品项
    item: {
      type: Object,
      default: () => ( {} )
    },
    index: {
      type: [Number, String],
      default: 0
    },
    // 动态商品项，切色块时
    curData: {
      type: Object,
      default: () => ( {} )
    },
    // 商品项配置
    config: {
      type: Object,
      default: () => ( {} )
    },
    constantData: {
      type: Object,
      default: () => ( {} )
    },
    column: {
      type: [Number, String],
      default: 2
    },
    showSpuImg: {
      type: Boolean,
      default: false
    },
    selectedColorId: {
      type: Number,
      default: 0
    },
    elSmallGap: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      selectIndex: 0,
      isEnd: false,
      lastColor: false,
    }
  },
  computed: {
    currency () {
      return this.constantData.currency
    },
    cssRight () {
      return this.constantData.GB_cssRight
    },
    showNextBtn () {
      const relateColorLen = this.item.relatedColor.length
      if (this.column == 2) {
        return relateColorLen > 5
      }

      if (this.column == 2.3) {
        return relateColorLen > 4
      }

      if ([2.9, 3, 3.3, '3l', '3.3l'].includes(this.column)) {
        return relateColorLen > 3
      }
      return false
    }
  },
  watch: {
    async selectIndex () {
      const colorItem = this.item.relatedColor[this.selectIndex]
      const colorGoodsId = Number(colorItem.goods_id)

      if (this.selectedColorId === colorGoodsId) return

      this.$emit('colorClick',  colorItem, colorGoodsId)
    }
  },
  methods: {
    transformImg,
    handleCheckEnd (isEnd) {
      this.isEnd = isEnd
    },
    // 下翻3个色块
    scrollToNext () {
      this.$refs.scrollBar.scrollToNext(3)
    }
  }
}
</script>

<style lang="less">
.product-item {
  &__colors-wrapper {
    position: relative;
    &_col-2 {
      width: 160px;
    }
    &_col-23 {
      width: 128px;
    }
    &_col-29,
    &_col-3,
    &_col-3l,
    &_col-33,
    &_col-33l {
      width: 96px;
    }
    .colors-wrapper {
      &__next-btn {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #fff;
        width: 32px;
        height: 18px;
        line-height: 18px;
        color: #666;
        > i {
          font-size: 16px;
        }
        > i.rotate {
          transform: rotate(180deg);
          transform-origin: center;
          display: inline-block;
        }
      }
    }
    .S-radio::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 30px;
      height: 30px;
    }
  }
  &__colors {
    margin-top: 0.2133rem;
    font-size: 0;
    height: 26px;
    padding-top: 0.1067rem;
    &_small-gap {
      margin-top: 0.1067rem;
    }
  }
}
</style>
