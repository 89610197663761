/**
 * @file 商品上的角标控制器
 */

import ItemLocateLabels from './ItemLocateLabels.vue'
import ProductItemImgTopLeft from './ProductItemImgTopLeft.vue'
import ProductItemImgBottomLeft from './ProductItemImgBottomLeft.vue'
import ProdcutItemImgTopRight from './ProductItemImgTopRight.vue'
import { getLocateLabelsAna } from '../analysis/locateLabelsAna'
import { isActivityPage, isFlashSalePage } from '../js/utils'

/**
 * 
 * @param {Function} h 不能删除，vue编译时会用到
 * @param {*} param1 
 * @returns 
 */
export const controlItemLabels = (h, {
  locateLabels,
  multiColumn,
  imgDesignWidth,
  isFlashSale,
  config,
  pretreatInfo,
  language,
  constantData,
  curData,
  hasBottomBadge,
  changeMarkFocus,
  promoLabelSrc,
  selectStatus,
  showSelect,
  showSmallSheinClubLabel,
  showHotTag,
}) => {
  const { showPriceReductionLabel, showOldLabel = false } = config
  // 专题页、闪购页需展示品牌、系列、大促角标的旧逻辑
  const needShowOldLabel = isActivityPage() || isFlashSalePage() || !!showOldLabel
  const locateLabelSet = new Set(Object.keys(locateLabels || {}).filter(key => Object.keys(locateLabels[key] || {}).length))
  // 左上角折扣角标
  const discountInfo = (!config.hideDiscountBadge && pretreatInfo?.discountInfo) || {}
  // 隐藏折扣标签
  const hiddenDiscountLabel = Boolean(showPriceReductionLabel) && !config.standardPrice

  const showDiscountLabel = !config.standardPrice && !showSmallSheinClubLabel && !config.hideDiscountBadge && discountInfo?.show && !hiddenDiscountLabel
  
  if (isFlashSale && showDiscountLabel) {
    // 闪购优先级最高
    locateLabelSet.delete('UPPER_LEFT_LABEL')
  }

  // @tips 收藏列表商品项有个checkbox
  const topRightProps = locateLabelSet.has('UPPER_RIGHT_LABEL') ? {
    promoLabelSrc: '',
  } : {
    promoLabelSrc,
  }

  const locateLabelsAna = getLocateLabelsAna(locateLabels, locateLabelSet)

  return {
    locateLabelsAna,
    ItemLocateLabels: () => locateLabelSet.size ? <ItemLocateLabels
      locate-labels={locateLabels}
      multi-column={multiColumn}
      lazy-img={constantData.LAZY_IMG_PIXEL}
      img-design-width={imgDesignWidth} /> : null,

    ProductItemImgTopLeft: () => locateLabelSet.has('UPPER_LEFT_LABEL') ? null : <ProductItemImgTopLeft
      cur-data={curData}
      constant-data={constantData}
      language={language}
      config={config}
      pretreat-info={pretreatInfo}
      multi-column={multiColumn}
      isFlashSale={isFlashSale}
      showDiscountLabel={showDiscountLabel}
      discount-info={discountInfo}
      show-hot-tag={showHotTag}
    />,

    ProductItemImgBottomLeft: () => locateLabelSet.has('LOWER_LEFT_LABEL') ? null : <ProductItemImgBottomLeft
      pretreat-info={pretreatInfo}
      config={config}
      has-bottom-badge={hasBottomBadge}
      constant-data={constantData}
      img-design-width={imgDesignWidth}
      need-show-old-label={needShowOldLabel}
      vOn:onFocus={() => changeMarkFocus(true)}
      vOn:onBlur={() => changeMarkFocus(false)}
    />,

    ProdcutItemImgTopRight: () => <ProdcutItemImgTopRight
      pretreat-info={pretreatInfo}
      cur-data={curData}
      config={config}
      constant-data={constantData}
      select-status={selectStatus}
      show-select={showSelect}
      img-design-width={imgDesignWidth}
      need-show-old-label={needShowOldLabel}
      {...{ props: topRightProps }}
      vOn:onFocus={() => changeMarkFocus(true)}
      vOn:onBlur={() => changeMarkFocus(false)}
    />
  }
}
