<template>
  <div 
    class="price-placeholder"
    :style="[isShowLabel ? { height: '0.1067rem' } : {}]"
  ></div>
</template>

<script>
export default {
  name: 'PricePlaceholder',
  data() {
    return {
      isShowLabel: true,
    }
  },
  mounted() {
    this.isShowLabel = false
  },
}
</script>
