<script lang="jsx">
export default {
  name: 'ProductItemSheinClubDiscount',
  functional: true,
  props: {
    discountValue: {
      type: [String, Number],
      default: ''
    },
    publicCdn: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'big'
    }
  },
  render(h, { props, data }) {
    return (
      props.discountValue
        ? (
          props.type === 'big' 
            ? (
              <div
                class={[
                  'product-item__sheinclub-discount',
                  data.class,
                  data.staticClass,
                ]}
                style={[data.style, data.staticStyle]}
              >
                <div class="sheinclub-discount__logo">
                  <img
                    class="logo-img"
                    src={`${props.publicCdn}/pwa_dist/images/pay_vip/sui_icon_paidmember_Label@3x-91f33d47f8.png`}
                  />
                </div>
                <div class="sheinclub-discount__discount">
                  -{props.discountValue}%
                </div>
              </div>
            ) 
            : (
              <div
                class={[
                  'product-item__sheinclub-discount_small',
                  data.class,
                  data.staticClass,
                ]}
                style={[data.style, data.staticStyle]}
              >
                <img
                  class="logo-img"
                  src={`${props.publicCdn}/pwa_dist/images/pay_vip/sui_icon_paidmember_Label_s-ab6e94a579.png`}
                />
                <div class="sheinclub-discount__discount">
                  -{ props.discountValue }%
                </div>
              </div>
            )
        )
        : null
    )
  },
}
</script>

<style lang="less">
.product-item {
  &__sheinclub-discount {
    .font-dpr(24px);
    position: relative;
    display: inline-block;
    height: 14px;
    line-height: 14px;
    margin-top: 0.0533rem;
    padding-left: 68px/* rtl:ignore */;
    direction: ltr/* rtl:ignore */;
    margin-right: -10px/* rtl:ignore */;
    &_small {
      .font-dpr(24px);
      position: relative;
      display: inline-block;
      height: 14px;
      line-height: 14px;
      margin-top: 0.0533rem;
      padding-left: 16px/* rtl:ignore */;
      margin-right: -10px/* rtl:ignore */;
      direction: ltr/* rtl:ignore */;
      >.logo-img {
        position: absolute;
        left: 0/* rtl:ignore */;
        top: 0;
        width: 16px !important; /* stylelint-disable-line declaration-no-important */
        height: 14px !important; /* stylelint-disable-line declaration-no-important */

        // transform: rotateY(0) /* rtl: rotateY(180deg) */;
      }
      .sheinclub-discount {
        &__discount {
          min-width: 33px;
          height: 21px;
          line-height: 21px;
          text-align: center;
          background: #FFE2D3;
          color: #873C00;
          font-weight: bold;
          transform: scale(0.667);
          transform-origin: left top/* rtl:ignore */;
          padding: 0 3px;
        }
      }
    }
    .sheinclub-discount {
      &__logo {
        >.logo-img {
          position: absolute;
          left: 0/* rtl:ignore */;
          top: 50%;
          transform: translateY(-50%);
          width: 68px;
          height: auto;
        }
      }
      &__discount {
        min-width: 33px;
        height: 21px;
        line-height: 21px;
        text-align: center;
        background: #873C00;
        color: #FFE2D3;
        border-radius: 0 2px 2px 0/* rtl:ignore */;
        font-weight: bold;
        transform: scale(0.667);
        transform-origin: left top/* rtl:ignore */;
        padding: 0 3px;
      }
    }
  }
}
</style>
