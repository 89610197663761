<template>
  <ProductItemOverlay 
    ref="overlay"
    v-model="showOverlay"
    :is-sold-out="isSoldOut"
  >
    <!-- 操作按钮组合 -->
    <ProductItemOperationBtns 
      from="operationPanel"
      :show="showOverlay"
      :cur-data="curData"
      :language="language"
      :config="config"
      :index="index"
      :is-sold-out="isSoldOut"
      @clickAddToBoard="(payload) => $emit('clickAddToBoard', payload)"
      @clickDelete="(payload) => $emit('clickDelete', payload)"
    />
  </ProductItemOverlay>
</template>

<script>
import ProductItemOverlay from './ProductItemOverlay'
import ProductItemOperationBtns from './ProductItemOperationBtns'

export default {
  name: 'ProductItemOperationPanel',
  components: {
    ProductItemOverlay,
    ProductItemOperationBtns
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    curData: {
      type: Object,
      default: () => ( {} )
    },
    language: {
      type: Object,
      default: () => ( {} )
    },
    config: {
      type: Object,
      default: () => ( {} )
    },
    index: {
      type: Number,
      default: -1
    },
    curOperateIndex: {
      type: Number,
      default: -1
    },
    isSoldOut: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showOverlay: {
      get () {
        return this.value
      },
      set (value) {
        return this.$emit('input', value)
      }
    }
  },
  watch: {
    curOperateIndex (curOperateIndex) {
      if (curOperateIndex != this.index) {
        this.$refs.overlay.closeOverlay()
      }
    }
  }
}
</script>
