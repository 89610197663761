<script lang="jsx">
export default {
  name: 'ProductItemUserBehaviorLabel',
  functional: true,
  props: {
    userBehaviorLabelInfo: {
      type: Object,
      default: () => ({})
    }
  },
  render(h, { props }) {
    const style = {
      color: props.userBehaviorLabelInfo.fontColor,
      backgroundColor: props.userBehaviorLabelInfo.backgroundColor,
    }
    const icon = props.userBehaviorLabelInfo.icon
    return (
      // 用户行为标签，ccc + tsp配置来的
      <div 
        class={{
          'product-item__user-behavior': true,
          'product-item__user-behavior_gap': icon
        }}
        style={style}
      >
        {icon ? <img src={icon} /> : null}
        {props.userBehaviorLabelInfo.labelLang}
      </div>
    )
  }
}
</script>

<style lang="less">
.product-item {
  &__user-behavior {
    .font-dpr(20px);
    .text-overflow();
    position: relative;
    display: inline-block;
    max-width: 100%;
    height: 0.4267rem;
    line-height: 0.4267rem;
    margin-top: 0.1067rem;
    &_gap {
      padding-left: 0.3733rem;
    }
    >img {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 0.32rem;
      height: 0.32rem;
      margin-right: 0.0533rem;
    }
  }
}
</style>
