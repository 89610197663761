<script lang="jsx">
import ProductItemAddToBag from './widgets/ProductItemAddToBag.vue'

const SaveBtn = () => import('./BottomRightSaveBtn')
export default {
  name: 'ProductItemImgBottomRight',
  functional: true,
  props: {
    config: {
      type: Object,
      default: () => ( {} )
    },
    // 动态商品项，切色块时
    curData: {
      type: Object,
      default: () => ( {} )
    },
    language: {
      type: Object,
      default: () => ( {} )
    },
    index: {
      type: Number,
      default: 0
    },
    constantData: {
      type: Object,
      default: () => ( {} )
    },
    moduleFrom: {
      type: String,
      default: ''
    },
    showAddBagBtn: {
      type: Boolean,
      default: false
    },
    needShowSpuImg: {
      type: Boolean,
      default: false
    },
    isSoldOut: {
      type: Boolean,
      default: false
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    relatedColor: {
      type: Array,
      default: () => []
    }
  },
  render(h, { props, listeners }) {
    const { constantData, config, index, moduleFrom, curData, language, isSoldOut, showSelect, relatedColor } = props || {}
    const { showLikeBtn, showAddBagAfterGoodsPrice, showAddBagBtn, showMultiColorIcon } = config || {}
    const { PUBLIC_CDN, IS_RW } = constantData || {}

    const needShowLikeBtn = showLikeBtn
    const needShowAddBagBtn = !showAddBagAfterGoodsPrice && showAddBagBtn && !isSoldOut && !showSelect
    const showColorIcon = showMultiColorIcon && relatedColor?.length > 0
    const addToBagStyle = { height: 'unset' }
    let rightIconEl = null
    if (needShowLikeBtn) {
      // 收藏 icon
      rightIconEl = (
        <SaveBtn
          cur-data={curData}
          config={config}
          language={language}
          index={index}
          module-from={moduleFrom}
          vOn:showAddBoard={listeners['showAddBoard']}
          vOn:toggleWish={listeners['toggleWish']}
        />
      )
    } else if (needShowAddBagBtn) {
      // 加车 icon
      rightIconEl = (
        <ProductItemAddToBag
          box-shadow={false}
          size="small"
          custom-style={addToBagStyle}
          is-rw={IS_RW}
          vOn:openQuickAdd={listeners['openQuickAdd']}
        />
      )
    } else if (showColorIcon) {
      // 多色块 icon
      rightIconEl = (
        <img
          class="product-item__color-icon"
          src={`${PUBLIC_CDN}/pwa_dist/images/colorMore-89e40835d5.png`}
        />
      )
    }

    return rightIconEl
  },
}
</script>

<style lang="less">
  /*stylelint-disable*/
  .product-item {
    &__color-icon {
      position: absolute;
      right: 0.1rem;
      bottom: 0.1rem;
      width: 0.48rem;
      height: auto;
    }
  }
</style>
