<script lang="jsx">
import { transformImg } from '@shein/common-function'

export default {
  name: 'ProductItemImgBottomLeft',
  functional: true,
  props: {
    pretreatInfo: {
      type: Object,
      default: () => ( {} )
    },
    needShowOldLabel: {
      type: Boolean,
      default: false,
    },
    // 是否有底部角标，对样式做区别
    hasBottomBadge: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => ( {} )
    },
    constantData: {
      type: Object,
      default: () => ( {} )
    },
    imgDesignWidth: {
      type: Number,
      default: 0
    }
  },
  render(h, { props, data, listeners }) {
    // 图片左下角标
    const imgBottomLeftLabel = props.pretreatInfo?.imgBottomLeft || null
    const seriesLogo = props.pretreatInfo?.seriesLogo || null
    const showSeriesLogo = seriesLogo && props.config.showSeriesBrand && props.needShowOldLabel
    const showImgBottomLeft = showSeriesLogo || imgBottomLeftLabel
    // shein 系列图片, 兜底大码图，无需 transformImg
    const seriesImg = props.pretreatInfo.bigImgOut ? seriesLogo : transformImg({ img: seriesLogo })
    const imgDesignWidth = props.imgDesignWidth || 0

    const handleFocus = () => listeners?.['onFocus']?.()
    const handleBlur = () => listeners?.['onBlur']?.()

    let labelEl = null
    if (showSeriesLogo) {
      // shein 系列
      labelEl = (
        <img
          src={props.constantData.LAZY_IMG_PIXEL}
          data-src={seriesImg}
          data-design-width={imgDesignWidth}
          class="img-bottomleft__series lazyload"
          tabindex="0"
          aria-label={props.pretreatInfo?.seriesLogoName || ''}
          role="img"
          vOn:focus={handleFocus}
          vOn:blur={handleBlur}
        />
      )
    } else if (imgBottomLeftLabel) {
      // cccTsp标签
      labelEl = (
        <div
          role="contentinfo"
          class={[
            'img-bottomleft__badge',
            {
              'img-bottomleft__badge_bottom-gap': props.hasBottomBadge
            }
          ]}
          style={{ color: '', backgroundColor: '' }}
        >
          {imgBottomLeftLabel.text}
        </div>
      )
    }

    return (
      showImgBottomLeft ? (
        <div class={['product-item__img-bottomleft', data.class, data.staticClass]}>{labelEl}</div>
      ) : null
    )
  },
}
</script>

<style lang="less">
.product-item {
  &__img-bottomleft {
    position: absolute;
    left: 0;
    bottom: 0;
    .img-bottomleft {
      &__series {
        width: 100%;
        height: auto !important; /* stylelint-disable-line declaration-no-important */
      }
      &__badge {
        position: absolute;
        left: .1067rem;
        bottom: .1867rem;
        padding: 0 .1067rem;
        height: .48rem;
        line-height: .48rem;
        &_bottom-gap {
          bottom: calc( .1067rem + .48rem );
        }
      }
    }
  }
}
</style>
