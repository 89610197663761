<script lang="jsx">
export default {
  name: 'DiscountLabel',
  functional: true,
  inject: ['constantData'],
  props: {
    type: {
      type: String,
      default: 'default'
    },
    // 折扣信息
    discountInfo: {
      type: Object,
      default: () => ( {} )
    },
    multiColumn: {
      type: Boolean,
      default: false
    },
    // 是否romwe
    isRW: {
      type: Boolean,
      default: false
    },
    // 是否ar站翻转  -50% 需要转成  %50-
    cssRight: {
      type: Boolean,
      default: false
    }
  },
  render(h, { props, data, slots, injections }) {
    const { constantData } = injections

    let { newStyle = false, newUnitDiscountString = [], unitDiscountString = '' } = props.discountInfo
    let isFromSheinClibeDiscount = props.discountInfo?.isFromSheinClibeDiscount || false // 是否来自会员, 如果是会员，颜色修改
    // 使用不贵降幅样式
    const useNewStyle = !!newStyle && newUnitDiscountString?.length

    
    // 反转
    if (unitDiscountString && (constantData?.GB_cssRight || props?.cssRight)) {
      unitDiscountString = unitDiscountString.replace(/(-?)(\d+(\.\d+)?)(%)/, '$4$2$1')
    }
    
    return (
      <div
        role="contentinfo"
        class={[
          'product-item__discount notranslate',
          data.class,
          data.staticClass,
          { 'discount_default': props.type === 'default' },
          { 'discount_promo': props.type === 'promo' },
          { 'discount_flash': props.type === 'flash' },
          { 'discount_flash-romwe': !!props.isRW },
          { 'discount_discount': props.type === 'discount' || props.type === 'newDiscount' },
          { 'discount_discount-new': props.type === 'newDiscount' },
          { 'discount__small-size': props.multiColumn },
          { 'super-deals-newstyle': useNewStyle },
          { 'discount_discount-clube': isFromSheinClibeDiscount },
        ]}
        style={[data.style, data.staticStyle]}
      >
        { slots().default }
        {
          !useNewStyle ? (
            <span class="product-item__discount-text">{ unitDiscountString }</span>
          ) : newUnitDiscountString.map(
            (item, index) => (
              <span key={index} class="product-item__discount-text">{ item }</span>
            ))
        }
      </div>
    )
  },
}
</script>

<style lang="less">
.product-item {
  &__discount {
    .font-dpr(20px);
    text-align: center;
    direction: ltr /* rtl: ignore */;
    line-height: 0.4267rem;
    padding: 0 0.1067rem;
    color: #fff;
    .sui_icon_flashsale_15px {
      .font-dpr(30px);
      display: block;
      margin-bottom: .04rem;
    }
    &.discount {
      &_default {
        background-color: @sui_color_main;
      }
      &_promo {
        background: @sui_color_promo;
      }
      &_flash {
        background: @sui_color_flash;
        color: #222;
      }
      &_flash-romwe {
        color: #fff;
      }
      &_discount {
        height: 0.3733rem;
        line-height: 0.3733rem;
        padding: 0 0.0533rem;
        border: 1px solid @sui_color_discount;
        border-radius: 2px;
        color: @sui_color_discount;
      }
      &_discount-new {
        background-color: @sui_color_discount;
        color: #fff;
      }
      &__small-size {
        .font-dpr(18px);
        line-height: 0.32rem;
      }
      // 如果是会员相关的，折扣样式（字体颜色这些存在变化）
      &_discount-clube {
        border: 1px solid @sui_color_club_rosegold_dark1;
        color: @sui_color_club_rosegold_dark1; 
      }
    }
  }
}
</style>
