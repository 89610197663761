<script lang="jsx">
import { transformImg } from '@shein/common-function'
import ImgBottomLabel from './UI/ImgBottomLabel.vue'
import ProductItemColorSet from './ProductItemColorSet.vue'
import ProductItemRomweSeries from './widgets/ProductItemRomweSeries.vue'
const ImgBottomBeltLabel = () => import('./ImgBottomBeltLabel.vue')

export default {
  name: 'ProductItemImgBottom',
  functional: true,
  props: {
    pretreatInfo: {
      type: Object,
      default: () => ( {} )
    },
    discountAmount: {
      type: Object,
      default: () => ( {} )
    },
    config: {
      type: Object,
      default: () => ( {} )
    },
    // 大码
    plusSize: {
      type: String,
      default: ''
    },
    // 是否有底部角标，对样式做区别
    hasBottomBadge: {
      type: Boolean,
      default: false
    },
    // romwe系列
    seriesOrBrandLogoBottom: {
      type: String,
      default: ''
    },
    // romwe原创系列
    romweOriginals: {
      type: String,
      default: ''
    },
    constantData: {
      type: Object,
      default: () => ( {} )
    },
    language: {
      type: Object,
      default: () => ( {} )
    },
    comingSoon: {
      type: Boolean,
      default: false  
    },
    isSoldOut: {
      type: Boolean,
      default: false
    },
    beltLabel: {
      type: [Object, Boolean],
      // false不会渲染组件
      default: false,
    },
    showBestDeal: {
      type: Boolean,
      default: false
    },
    followLabelVisible: {
      type: Boolean,
      default: false,
    },
    relatedColor: {
      type: Array,
      default: () => []
    },
    // 到手价折扣率
    estimatedDiscount: {
      type: String,
      default: ''
    },
    // 到手价折扣金额
    estimatedDiscountPrice: {
      type: Object,
      default: () => ({})
    },
    // 是否满足到手价门槛
    estimatedReachTreshold: {
      type: Boolean,
      default: false
    },
    discountPrice: {
      type: String,
      default: ''
    }
  },
  render(h, { props, slots }) {
    const { beltLabel, followLabelVisible, constantData, pretreatInfo, config, language, isSoldOut, showBestDeal, comingSoon, relatedColor, hasBottomBadge, seriesOrBrandLogoBottom, romweOriginals, plusSize, discountAmount, estimatedDiscount, estimatedDiscountPrice, estimatedReachTreshold, discountPrice } = props || {}
    const { showLeftStock, colorSetPresentedType, sa, showNewDiscountBlet, standardPrice } = config || {}
    const { activity_from } = sa || {}
    const { stockLeft } = pretreatInfo || {}
    const { IS_RW } = constantData || {}
    // 仅shein
    const showStockLeft = stockLeft && showLeftStock && (IS_RW ? activity_from !== 'wishlist' : true)

    const labelInfo = (() => {
      // 保留款,跟价款腰带
      const beltInfo = {
        type: ['orange', '12PX'],
        icon: 'sui_icon_best_deal_16px',
        text: showBestDeal ? language.SHEIN_KEY_PWA_24438 : language.SHEIN_KEY_PWA_26113,
        lineClamp: showBestDeal ? 2 : 1,
        center: true,
      }
      const stockLeftInfo = {
        type: ['black', '12PX'],
        text: stockLeft,
        center: true,
      }
      if (showBestDeal) {
        return [beltInfo]
      }
      if (followLabelVisible && showStockLeft) {
        return [stockLeftInfo, beltInfo]
      }
      if (followLabelVisible) {
        return [beltInfo]
      }
      if (showStockLeft) { // 剩余库存 例子：only xx left
        return [stockLeftInfo]
      } else if (isSoldOut && comingSoon) { // coming soon
        return [{
          type: ['black', '12PX'],
          text: language.SHEIN_KEY_PWA_15059,
          center: true
        }]
      }

      return []
    })()
    const colorSetType = (() => {
      const map = {
        ProColorA: 'horizontal',
        ProColorB: 'vertical',
      }
      return map[colorSetPresentedType]
    })()
    const colors = (() => {
      const colorItems = relatedColor.filter(colorItem => colorItem.stock !== 0)
      const images = colorItems.map(item => item.goods_color_image).filter(image => image)
      return images.map(image => transformImg({
        img: image
      }))
    })()

    // 补充条件， wiki: pageId=1347754607, 命中则不展示腰带
    const needShowLabels = (beltLabel || showNewDiscountBlet) && !standardPrice
    return (
      <div class="product-item__img-bottom">
        {/* 图片底部slot */}
        {slots().imgBottom}
        {/* 色块 */}
        {
          colorSetType && colors.length > 1 
            ? <ProductItemColorSet direction={colorSetType} colors={colors} /> 
            : null
        }
        {/* 低价爆品 > 剩余库存 例子：only xx left > coming soon */}
        {
          labelInfo.map(infoItem => (<ImgBottomLabel
            type={infoItem.type}
            center={infoItem.center}
            label-text={infoItem.text}
            label-icon={infoItem.icon}
            line-clamp={infoItem.lineClamp}
          />))
        }
        {
          (needShowLabels)
            ? <ImgBottomBeltLabel
              belt-label={beltLabel}
              show-new-discount-blet={showNewDiscountBlet}
              discount-amount={discountAmount}
              estimated-discount={estimatedDiscount}
              estimated-discount-price={estimatedDiscountPrice}
              estimated-reach-treshold={estimatedReachTreshold}
              pretreat-info={pretreatInfo}
              discount-price={discountPrice}
            /> // 腰带
            : (
              IS_RW && hasBottomBadge
                ? (
                  // romwe 系列, 大码
                  <ProductItemRomweSeries
                    series-or-brand-logo-bottom={seriesOrBrandLogoBottom}
                    romwe-originals={romweOriginals}
                    plus-size={plusSize}
                    constant-data={constantData}
                  />
                ) : null
            )
        }
      </div>
    )
  },
}
</script>

<style lang="less">
.product-item {
  &__img-bottom {
    .font-dpr(24px);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: self-end;
  }
}
</style>
