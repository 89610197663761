<script lang="jsx">
import { Checkbox as SCheckbox } from '@shein/sui-mobile'
import { transformImg } from '@shein/common-function'

export default {
  name: 'ProductItemImgTopRight',
  functional: true,
  components: {
    SCheckbox
  },
  props: {
    pretreatInfo: {
      type: Object,
      default: () => ( {} )
    },
    config: {
      type: Object,
      default: () => ( {} )
    },
    curData: {
      type: Object,
      default: () => ( {} )
    },
    constantData: {
      type: Object,
      default: () => ( {} )
    },
    selectStatus: {
      type: Boolean,
      default: false
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    needShowOldLabel: {
      type: Boolean,
      default: false,
    },
    promoLabelSrc: {
      type: String,
      default: ''
    },
    imgDesignWidth: {
      type: Number,
      default: 0
    }
  },
  render(h, { props, listeners }) {
    // 视频icon
    const showVideoIcon = props.config.showVideoIcon && props.curData?.video_url
    // 品牌
    const brandLogo = props.pretreatInfo?.brandLogo || null
    const brandLogoName = props.pretreatInfo?.brandLogoName
    const showBrandLogo = brandLogo && props.config.showSeriesBrand && props.needShowOldLabel
    const showPromoLabel = props.promoLabelSrc && props.needShowOldLabel
    const showImgTopRight = showVideoIcon || showBrandLogo || props.showSelect || showPromoLabel
    const imgDesignWidth = props.imgDesignWidth || 0

    const handleFocus = () => listeners?.['onFocus']?.()
    const handleBlur = () => listeners?.['onBlur']?.()

    let iconEl = null
    if (showPromoLabel) {
      // 大促标签
      iconEl = (
        <img
          class="product-item__promo-label lazyload"
          src={props.constantData.LAZY_IMG_PIXEL}
          data-src={transformImg({ img: props.promoLabelSrc })}
          data-design-width={imgDesignWidth}
          alt=""
        />
      )
    } else if (showVideoIcon) {
      // 视频 icon
      iconEl = (
        <div class="product-item__video-tag">
          <i class="iconfont icon-play1"></i>
        </div>
      )
    } else if (showBrandLogo) {
      // 品牌
      iconEl = (
        <img
          class="product-item__brand lazyload"
          src={props.constantData.LAZY_IMG_PIXEL}
          data-src={transformImg({ img: brandLogo })}
          data-design-width={imgDesignWidth}
          alt=""
          tabindex="0"
          aria-label={brandLogoName}
          vOn:focus={handleFocus}
          vOn:blur={handleBlur}
        />
      )
    }

    if (showImgTopRight) {
      return (
        <div class="product-item__img-topright">
          {iconEl}
          {/* 选择模式 */}
          {props.showSelect ? (
            <s-checkbox
              value={props.selectStatus}
              class="product-item__select"
              normal-style="black02-black"
              margin-r="0"
            />
          ) : null}
        </div>
      )
    } else {
      return null
    }
  },
}
</script>

<style lang="less">
.product-item__img-topright {
  .product-item {
    &__video-tag {
      position: absolute;
      top: 0.2133rem;
      right: .2rem/*rtl:ignore*/;
      width: 0.48rem;
      height: 0.44rem;
      background: #FFFFFF;
      border-radius: 0.1067rem;
      color: #ff696e;
      .flexbox();
      .align-center();
      .pack-center();
    }
    &__brand,
    &__promo-label {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto !important; /* stylelint-disable-line declaration-no-important */
    }
    &__select {
      &.S-checkbox {
        position: absolute;
        top: 0.16rem;
        right: 0.16rem;
        z-index: @zindex-select;
      }
    }
  }
}
</style>
